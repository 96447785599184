import useSWR from 'swr'

import List from '../ui/list'
import { getUsers } from '../../api'

const UsersList = () => {
  const { data: users, isLoading } = useSWR('/api/users', getUsers)

  const headers = [{ label: 'Email' }, { label: 'Invoices' }]
  const rows: Array<any[]> = []

  if(isLoading) return (<div>Loading...</div>)

  if(users && users.data) {
    users.data.forEach((user: any) => {
      rows.push([user.email, user.invoiceCount])
    })
  }

  const minAmountInvoices = (amount: number) => users?.data.filter((user: any) => user.invoiceCount > amount).length

  return (
    <>
      <p><b>Total Users:</b> {users?.data.length}</p>
      <p><b>Total Users with more than 5 invoices:</b> {minAmountInvoices(5)}</p>
      <p><b>Total Users with more than 10 invoices:</b> {minAmountInvoices(10)}</p>
      <List headers={headers} rows={rows} />
    </>
  )
}

export default UsersList
