import React from 'react'
import { Link } from 'react-router-dom'

import { Invoice, InvoiceStatus } from '../../types'
import List from '../ui/list'
import * as numberLib from '../../lib/number'
import ActivityBadge from './activityBadge'
import Amount from '../ui/currency/amount'

/**
 * Shows a list of invoices.
 * Looks like a table with the following columns:
 * Issue Date,
 * Code,
 * Client,
 * Amount,
 * Status: Open, Paid, Overdue
 * Activity: Draft, Email Sent, Client Viewed
 * @returns
 */
type Props = { invoices: Array<Invoice>}
const InvoiceList: React.FC<Props> = ({ invoices }) => {
  const headers = [
    { label: <h6>Issue Date</h6> },
    { label: <h6># Code</h6> },
    { label: <h6>Client</h6> },
    { label: <h6>Total</h6> },
    { label: <h6 className='text-center'>Status</h6> },
    { label: <h6 className='text-center fs-7 d-none d-sm-block'>Activity</h6> }
  ]

  const rows: Array<any[]> = []

  const renderStatus = (status: InvoiceStatus) => {
    switch(status) {
      case InvoiceStatus.PAID:
        return <div className='badge bg-success'>Paid</div>
      case InvoiceStatus.OVERDUE:
        return <div className='badge bg-danger'>Overdue</div>
      default:
        return <div className='badge bg-light text-dark'>Open</div>
    }
  }

  if(invoices) {
    invoices.forEach((invoice: Invoice) => {
      rows.push([
        <small className='text-muted'>{new Date(invoice.issueDate).toLocaleDateString()}</small>,
        <small className='text-muted'><Link to={`/invoices/${invoice._id}`}>{invoice.code}</Link></small>,
        <small className='text-muted text-truncate'><Link to={`/clients/${invoice.client?._id}`}>{invoice.client?.tradingName}</Link></small>,
        <b className='text-primary'>
          <Amount value={invoice.total as number} currencySymbol={invoice?.businessDetails?.currency?.symbol} />
        </b>,
        <div className='text-center'>{renderStatus(invoice.status as InvoiceStatus)}</div>,
        <div className='d-none d-sm-block text-center'><ActivityBadge activity={invoice.lastActivity} /></div>
      ])
    })
  }

  return <List headers={headers} rows={rows} />
}

export default InvoiceList
